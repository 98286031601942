@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("LovelyBalloon"),
    url("./fonts/LovelyBalloon.ttf") format("truetype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.card {
  background-color: #db4545;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 10px 0;
  width: fit-content;
  height: fit-content;
  padding: 3rem;
}


.App-header {
  font-family: 'Light';
  background-color: #f2f2f2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 4vmin);
  color: white;
}

.btn-yes {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.75rem;
  color: white;
  padding: 0.25rem 1rem;
  background-color: transparent;
  border: 2px solid #d0a0ec;
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 10px 0;

}

.btn-yes:hover {
  background-color: #d0a0ec;
  border: 2px solid #d0a0ec;
}

.btn-no {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.75rem;
  color: #f2f2f2;
  padding: 0.25rem 1rem;
  background-color: transparent;
  border: 2px solid #ff5e81;
  border-radius: 0.5rem;
  margin-right: 2rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 5px 0;
}

.btn-no:hover {
  background-color: #ff5e81;
  border: 2px solid #ff5e81;
}

.btn-no:active, .btn-yes:active  {
  background-color: transparent;
  box-shadow: none;
}

.App-link {
  color: #61dafb;
}


.modal {
  z-index: 999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;

}

.modal > .card-it-is-a-date {
  padding: 5rem;
  border-radius: 0.5rem;
  background-color: #d0a0ec;
  color: black;
  font-family: 'Light';
  font-size: calc(10px + 3vmin);
}
